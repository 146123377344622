// import Web3 from 'web3';
// import { Connection, PublicKey } from '@solana/web3.js';

// wallets: [
//     { name: 'Phantom', type: 'phantom' },
//     { name: 'MetaMask', type: 'metamask' },
//     { name: 'Solflare', type: 'solflare' },
//     { name: 'Torus', type: 'torus' },
//     { name: 'Ledger', type: 'ledger' },
//     { name: 'WalletConnect', type: 'walletconnect' }
// ],
// selectedNetwork: 'mainnet-beta',

const state = {
    isConnecting: false,
    isConnected: false,
    walletNetwork: 'solana', // always solana
    walletAddr: null,
    walletType: '', // 'phantom' or 'solflare'
    walletData: null, // not used for solana
    error: null,
};
  
const mutations = {

    SET_CONNECTING(state, isConnecting) {
      state.isConnecting = isConnecting;
    },
    SET_CONNECTED(state, isConnected) {
        state.isConnected = isConnected;
    },
    SET_WALLET_ADDR(state, walletAddr) {
        state.walletAddr = walletAddr;
    },
    SET_WALLET_TYPE(state, walletType) {
      state.walletType = walletType;
    },
    SET_WALLET_DATA(state, walletData) {
      state.walletData = walletData;
    },

};
  
const actions = {

    async connectWallet({ commit, dispatch }, walletType) {
        commit('SET_CONNECTING', true);
        commit('SET_WALLET_TYPE', walletType);
        try {
            if(walletType === 'phantom') {
                await dispatch('phantom/connect', null, { root: true });
                commit('SET_CONNECTED', true);
            } else if(walletType === 'solflare') {
                await dispatch('solflare/connect', null, { root: true });
                commit('SET_CONNECTED', true);
            } else if (walletType === 'adapter') {
                await dispatch('adapter/connect', null, { root: true });
                commit('SET_CONNECTED', true);
            }
        } catch (err) {
            console.error(`Connection error for ${walletType}:`, err);
            // dispatch('showNotification', { 
            //     message: `Connection error for ${walletType}: ${err.message}`, 
            //     type: 'error' 
            //   }, { root: true });
        } finally {
            commit('SET_CONNECTING', false);
        }
    },
  
    async disconnectWallet({ state, commit, dispatch }) {
        if (state.walletType === 'phantom') {
            await dispatch('phantom/disconnect', null, { root: true });
        } else if (state.walletType === 'solflare') {
            await dispatch('solflare/disconnect', null, { root: true });
        } else if (state.walletType === 'adapter') {
            await dispatch('adapter/disconnect', null, { root: true });
        }
        commit('SET_CONNECTED', false);
        commit('SET_WALLET_TYPE', null);
    },
  
    async signMessage({ state, dispatch }, message) {
        if (!state.walletType) {
            throw new Error('No wallet connected');
        }
        try {
            let signature;
            if (state.walletType === 'phantom') {
                signature = await dispatch('phantom/signMessage', message, { root: true });
            } else if (state.walletType === 'solflare') {
                signature = await dispatch('solflare/signMessage', message, { root: true });
            }
            return signature;
        } catch (err) {
            throw new Error(`Error signing message: ${err.message}`);
        }
    },

    async checkConnection({ dispatch, commit }) {
        // Check Phantom
        if (await dispatch('phantom/checkIfInstalled', null, { root: true })) {
            try {
                const phantomProvider = await dispatch('phantom/getProvider', null, { root: true });
                const resp = await phantomProvider.connect({ onlyIfTrusted: true });
                dispatch('showNotification', { 
                    message: `Phantom wallet connected`, 
                    type: 'success' 
                }, { root: true });
                commit('SET_CONNECTED', true);
                commit('SET_WALLET_TYPE', 'phantom');
                commit('phantom/SET_CONNECTED', true, { root: true });
                commit('phantom/SET_WALLET_ADDR', resp.publicKey.toString(), { root: true });
            } catch (err) {
                if (err.message === 'User rejected the request.') {
                    // Happens when no wallet have an active connection
                    console.log("User rejected the connection request");
                } else {
                    throw err;
                }
                // console.log("Phantom wallet not connected");
            }
        }
        // Check Solflare
        if (await dispatch('solflare/checkIfInstalled', null, { root: true })) {
            try {
                const solflareProvider = await dispatch('solflare/getProvider', null, { root: true });
                if (solflareProvider.isConnected) {
                    dispatch('showNotification', { 
                        message: `Solflare wallet connected`, 
                        type: 'success' 
                    }, { root: true });
                    commit('SET_CONNECTED', true);
                    commit('SET_WALLET_TYPE', 'solflare');
                    commit('solflare/SET_CONNECTED', true, { root: true });
                    commit('solflare/SET_WALLET_ADDR', solflareProvider.publicKey.toString(), { root: true });
                }
            } catch (err) {
                console.log("Solflare wallet not connected");
            }
        }
    },

    async removeAllListeners({ dispatch }) {
        dispatch('phantom/removeListeners', null, { root: true });
        dispatch('solflare/removeListeners', null, { root: true });
    },

};

const getters = {

    // isAnyWalletConnected(state, getters, rootState) {
    //     return rootState.phantom.isConnected || rootState.solflare.isConnected;
    // },
    // connectedWalletType(state) {
    //     return state.walletType;
    // },
    // connectedPublicKey(state, getters, rootState) {
    //     if (state.walletType === 'phantom') {
    //         return rootState.phantom.publicKey;
    //     } else if (state.walletType === 'solflare') {
    //         return rootState.solflare.publicKey;
    //     }
    //     return null;
    // },

};
  
export default { namespaced: true, state, mutations, actions, getters };