import { createRouter, createWebHistory } from 'vue-router';

const routes = [
//   { path: '/', name: 'home', component: () => import('@/views/Home.vue') },
  { path: '/', name: 'list', component: () => import('@/views/List.vue'), props: true },
//   { path: '/city', name: 'city', component: () => import('@/views/City.vue'), props: true },
  { path: '/launch', name: 'launch', component: () => import('@/views/Launch.vue'), props: true },
  { path: '/my', name: 'my', component: () => import('@/views/ListMy.vue'), props: true },
  { path: '/info/:contract', name: 'info', component: () => import('@/views/Info.vue'), props: true },
  { path: '/profile', name: 'profile', component: () => import('@/views/Profile.vue'), props: true },
  { path: '/drop', name: 'drop', component: () => import('@/views/Drop.vue'), props: true },
  { path: '/points', name: 'points', component: () => import('@/views/Points.vue'), props: true },
  { path: '/invite', name: 'invite', component: () => import('@/views/Invite.vue'), props: true },
  { path: '/about', name: 'about', component: () => import('@/views/About.vue'), props: true },
  { path: '/terms', name: 'terms', component: () => import('@/views/Terms.vue'), props: true },
  { path: '/feedback', name: 'feedback', component: () => import('@/views/Feedback.vue'), props: true },
  { path: '/history', name: 'history', component: () => import('@/views/History.vue'), props: true },
  { path: '/settings', name: 'settings', component: () => import('@/views/Settings.vue'), props: true },
  
  { path: '/test', name: 'test', component: () => import('@/views/Test.vue'), props: true },

//   { path: '/twitter', name: 'twitter', component: () => import('@/views/TwitterCallback.vue'), props: true },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
                const element = document.querySelector(to.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            return { top: 0 } // return { x: 0, y: 0 }
        }
      },
  });

router.beforeEach((to, from, next) => {
  console.log('Navigation to:', to.name);
  // const isAuthenticated = !!localStorage.getItem('wallet');
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ name: 'Home' });
  } else {
    next();
  }
});

export default router;