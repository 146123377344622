// import { PublicKey } from '@solana/web3.js';
// import api from '../api'; // Make sure this is correctly imported

const state = {
    isConnected: false,
    isSigning: false,
    publicKey: null,
    isInstalled: false,
};

const mutations = {

    SET_CONNECTED(state, isConnected) {
        state.isConnected = isConnected;
    },
    SET_SIGNING(state, isSigning) {
        state.isSigning = isSigning;
    },
    SET_WALLET_ADDR(state, publicKey) {
        state.publicKey = publicKey;
        this.commit('wallets/SET_WALLET_ADDR', publicKey, { root: true });
    },
    SET_INSTALLED(state, isInstalled) {
        state.isInstalled = isInstalled;
    },

};

const actions = {

    // provider.on('connect', this.handlePhantomConnect);
    // provider.on('disconnect', this.handleWalletDisconnect);
    // provider.on('accountChanged', this.handlePhantomAccountChanged);

    async init({ commit, dispatch }) {
        // console.log('Store - Init phantom dispatched');
        const isInstalled = await dispatch('checkIfInstalled');
        commit('SET_INSTALLED', isInstalled);
        if (isInstalled) {
            const provider = await dispatch('getProvider');
            if(provider) {
                provider.on('connect', (publicKey) => {
                    console.log(`Phantom Event - connect + ${publicKey.toString()}`);
                    commit('SET_CONNECTED', true);
                    commit('SET_WALLET_ADDR', publicKey.toString());
                });
                provider.on('disconnect', () => {
                    console.log(`Phantom Event - disconnect`);
                    commit('SET_CONNECTED', false);
                    commit('SET_WALLET_ADDR', null);
                });
                provider.on('accountChanged', (publicKey) => {
                    if (publicKey) {
                        console.log(`Phantom Event - account changed to ${publicKey.toBase58()}`);
                        commit('SET_WALLET_ADDR', publicKey.toString());
                    } else {
                        console.error(`Phantom Event - account changed but no wallet address`);
                        // Attempt to reconnect to Phantom
                        // provider.connect().catch((error) => {
                        // ...
                        // });
                        commit('SET_CONNECTED', false);
                        commit('SET_WALLET_ADDR', null);
                    }
                });
            }
        }


    },

    async connect({ commit, dispatch }) {

        // The recommended and easiest way to connect to Phantom is by calling window.phantom.solana.connect()
        // If a phantom object exists, Solana apps can interact with Phantom via the API found at window.phantom.solana. 
        // This solana object is also available at window.solana to support legacy integrations.
        
        // if (!state.isInstalled) {
        //     throw new Error('Phantom wallet is not installed.');
        // }
        const provider = await dispatch('getProvider');
        if (!provider) {
            throw new Error('Solflare wallet is not installed');
        }
        try {

            // const provider = await dispatch('getProvider');
            // if (!provider.isConnected) {
            //     await provider.connect();
            //     if (provider.isConnected) {
            //         this.walletAddress = provider.publicKey.toString();
            //         // await this.getWalletInfo();
            //         await this.signMessage();
            //     }
            // }

            await provider.connect();
            
            if (provider.isConnected) {
                const publicKey = provider.publicKey.toString();
                commit('SET_WALLET_ADDR', publicKey);
                commit('SET_CONNECTED', true);
                commit('SET_SIGNING', true);

                // Get the message to sign from the backend
                const message = await dispatch('getSignMessage', {
                    publicKey,
                    walletType: 'phantom'
                }, { root: true });
      
                const signedMessage = await dispatch('signMessage', message);

                // Authenticate with backend
                await dispatch('authenticateWallet', {
                    publicKey,
                    signedMessage,
                    walletType: 'phantom'
                }, { root: true });

                commit('SET_SIGNING', false);

                dispatch('showNotification', { 
                    message: 'Phantom wallet connected successfully', 
                    type: 'success' 
                }, { root: true });

            }
        } catch (err) {
            console.error('Phantom connection error:', err);
            dispatch('showNotification', { 
                message: `Failed to connect Phantom wallet: ${err.message}`, 
                type: 'error' 
            }, { root: true });
            throw err;
        }
    },

    async disconnect({ commit, dispatch }) {
        const provider = await dispatch('getProvider');
        if (provider.isConnected) {
            // alert('provider connected');
            await provider.disconnect();
            commit('SET_CONNECTED', false);
            commit('SET_WALLET_ADDR', null);
            dispatch('showNotification', { 
                message: 'Phantom wallet disconnected', 
                type: 'success' 
            }, { root: true });
        }
    },

    async signMessage({ dispatch }, message) {
        const provider = await dispatch('getProvider');
        if (!provider || !provider.isConnected) {
            throw new Error('Phantom wallet is not connected');
        }
        const encodedMessage = new TextEncoder().encode(message);
        const signedMessage = await provider.signMessage(encodedMessage, 'utf8');
        return signedMessage;
    },

    ifPhantomInstalled() {
        // const isPhantomInstalled = window.phantom?.solana?.isPhantom
        // if not - https://phantom.app/
        // return 'solana' in window && window.solana.isPhantom;
        return 'phantom' in window || 'solana' in window;
    },

    async checkIfInstalled() {
        // alert('checkIfInstalled = '+typeof window);
        // return dispatch('ifPhantomInstalled');
        // const provider = await dispatch('getProvider');
        // if(provider) {
        //     return true;
        // } else {
        //     return false;
        // }
        // return 'phantom' in window || 'solana' in window;
        return new Promise((resolve) => {
            if (typeof window !== 'undefined') {
                resolve('phantom' in window || 'solana' in window);
            } else {
                resolve(false);
            }
        });
    },

    async getProvider() {
        return new Promise((resolve) => {
            if (typeof window !== 'undefined') {
                resolve(window.phantom?.solana || window.solana);
            } else {
                resolve(null);
            }
        });
    },

    // getPhantomProvider() {
    //     if ('phantom' in window) {
    //         const provider = window.phantom?.solana;
    //         if (provider?.isPhantom) {
    //             return provider;
    //         }
    //     }
    //     if ('solana' in window) {
    //         return window.solana;
    //     }
    //     return null;
    // },

    removeListeners({ dispatch }) {
        dispatch('getProvider').then(provider => {
            if (provider) {
                provider.removeAllListeners('connect');
                provider.removeAllListeners('disconnect');
                provider.removeAllListeners('accountChanged');
            }
        });
    },

};

export default { namespaced: true, state, mutations, actions };