<template>

    <div @click="hideConnect()" class="fixed z-[100] inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center">
        <div @click.stop class="bg-slate-800 p-6 rounded-lg shadow-xl w-[80%] max-w-[500px]">
            <div class="flex flex-row justify-between">
                
                <h2 class="font-bold mb-4">Connect Solana Wallet</h2>
                <div @click="hideConnect()" class="p2 cursor-pointer" ><img src="@/assets/set/close.svg" class="w-5 h-5"></div>
                <!-- border-slate-400 rounded-full -->

            </div>
            <div class="flex flex-col gap-3">
                
                <!-- <button :class="isPhantomInstalled ? 'b3 bc' : 'b3 be'" @click="connectPhantom()" :disabled="isConnecting">
                    <span v-if="isConnecting && walletType === 'phantom'">Connecting...</span>
                    <span v-else>{{ isPhantomInstalled ? 'Connect Phantom' : 'Install Phantom' }}</span>
                </button>
                
                <button :class="isSolflareInstalled ? 'b3 bc' : 'b3 be'" @click="connectSolflare()" :disabled="isConnecting">
                    <span v-if="isConnecting && walletType === 'solflare'">Connecting...</span>
                    <span v-else>{{ isSolflareInstalled ? 'Connect Solflare' : 'Install Solflare' }}</span>
                </button> -->

                <div v-if="isPhantomSigning || isSolflareSigning" class="mb-2 text-slate-300">Signing...</div>

                <button @click="connectPhantom()" :disabled="isConnecting" class="b3 bc">
                    <span v-if="isConnecting && walletType === 'phantom'">Connecting...</span>
                    <span v-else-if="isSigning && walletType === 'phantom'">Awaiting Signature...</span>
                    <!-- <span v-else>{{ isPhantomInstalled ? 'Connect Phantom' : 'Install Phantom' }}</span> -->
                    <span v-else class="font-bold">Phantom</span>
                </button>
                <div v-if="!isPhantomInstalled" class="mb-2 text-slate-300"><img src="@/assets/set/warn.svg" class="w-5 h-5 inline-block"> Phantom wallet not detected</div>

                <button @click="connectSolflare()" :disabled="isConnecting" class="b3 bc">
                    <span v-if="isConnecting && walletType === 'solflare'">Connecting...</span>
                    <span v-else-if="isSigning && walletType === 'solflare'">Awaiting Signature...</span>
                    <!-- <span v-else>{{ isSolflareInstalled ? 'Connect Solflare' : 'Install Solflare' }}</span> -->
                    <span v-else class="font-bold">Solflare</span>
                </button>
                <div v-if="!isSolflareInstalled" class="mb-2 text-slate-300"><img src="@/assets/set/warn.svg" class="w-5 h-5 inline-block"> Solflare wallet not detected</div>
                
                <button @click="connectadapter()" :disabled="isConnecting" class="b3 bc">
                    <span v-if="isConnecting && walletType === 'adapter'">Connecting...</span>
                    <span v-else-if="isSigning && walletType === 'adapter'">Awaiting Signature...</span>
                    <span v-else class="font-bold">Mobile Wallet</span>
                </button>
                <div v-if="!isAdapterInstalled" class="mb-2 text-slate-300"><img src="@/assets/set/warn.svg" class="w-5 h-5 inline-block"> Mobile wallet not detected</div>

            </div>

            <div class="my-2 flex items-center">
                <div class="flex-grow h-px bg-slate-600"></div>
                <div class="mx-4 text-slate-300">or</div>
                <div class="flex-grow h-px bg-slate-600"></div>
            </div>

            <div class="mb-3">Sign Up with E-mail:</div>
            <!-- or Telegram -->
            <div class="flex flex-col gap-3">
                <button @click="showSign()"  class="b3 ba">Sign Up</button>
            </div>

        </div>
    </div>

</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    props: {
        // openConnect: {
        //     type: Boolean,
        //     required: true
        // }
    },
    // data() {
    //     return {
    //         showNotify: true
    //     }
    // },
    computed: {

        ...mapState('wallets', ['isConnecting', 'isConnected', 'walletNetwork', 'walletType', 'walletData', 'walletAddr']),
        ...mapState('phantom', {isPhantomInstalled: 'isInstalled', isPhantomSigning: 'isSigning' }),
        ...mapState('solflare', {isSolflareInstalled: 'isInstalled', isSolflareSigning: 'isSigning' }),
        ...mapState('adapter', {isAdapterInstalled: 'isInstalled', isAdapterSigning: 'isSigning' }),

        // ...mapGetters('wallets', [ 'isAnyWalletConnected', 'connectedWalletType', 'connectedPublicKey' ]),

    },
    methods: {

        ...mapActions('wallets', ['connectWallet', 'disconnectWallet', 'signMessage']),
        
        async connectPhantom() {
            if (!this.isPhantomInstalled) {
                this.openWalletInstallPage('phantom');
            } else {
                await this.connectWallet('phantom');
            }
        },
        
        async connectSolflare() {
            if (!this.isSolflareInstalled) {
                this.openWalletInstallPage('solflare');
            } else {
                await this.connectWallet('solflare');
            }
        },

        async connectadapter() {
            // if (!this.isadapterInstalled) {
                // this.openWalletInstallPage('adapter');
                // alert('No mobile wallet detected');
            // } else {
                await this.connectWallet('adapter');
            // }
        },

        openWalletInstallPage(wallet) {
            let url;
            if (this.isMobile()) {
                if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                url = wallet === 'phantom' ? 'https://apps.apple.com/app/phantom-solana-wallet/id1598432977' : 'https://apps.apple.com/app/solflare-wallet/id1580902717';
                } else if (/Android/i.test(navigator.userAgent)) {
                url = wallet === 'phantom' ? 'https://play.google.com/store/apps/details?id=app.phantom' : 'https://play.google.com/store/apps/details?id=com.solflare.mobile';
                }
            } else {
                url = wallet === 'phantom' ? 'https://phantom.app/' : 'https://solflare.com/';
            }
            if (url) {
                window.open(url, '_blank');
            }
        },
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },

        // Modal Control //
        showSign() {
            this.$emit('showSign');
        },
        hideConnect() {
            this.$emit('hideConnect');
        },

    }
}

</script>