import { createStore } from 'vuex';

// import api from '../api';

import sessActions from './sess.js';
import authActions from './auth.js';
import userActions from './user.js';
import wsModule from './ws.js';

import walletsModule from './wallets.js';
import phantomModule from './phantom.js';
import solflareModule from './solflare.js';
import adapterModule from './adapter.js';

const store = createStore({

modules: {
    wallets: walletsModule,
    phantom: phantomModule,
    solflare: solflareModule,
    adapter: adapterModule,
    ws: wsModule,
},

state: {

    notification: null,
    information: null,
    
    // GET PARAMS //
    utmSource: '',
    utmData: '',

    // SESSION AND AUTH //
    user: null,
    sessionToken: localStorage.getItem('sessionToken'),
    jwt: localStorage.getItem('jwt'),
    refreshToken: localStorage.getItem('refreshToken'),

    // GENERAL DATA //
    appData: null,
    appLoad: false,
    appError: false,
    appErrorCode: null,
    appErrorText: null,

},
mutations: {

    SET_NOTIFICATION(state, notification) {
        state.notification = notification;
    },
    SET_INFORMATION(state, information) {
        state.information = information;
    },
    UPDATE_COIN_IN_LIST(state, updatedCoin) {
        console.log('UPDATE_COIN_IN_LIST: '+JSON.stringify(updatedCoin));
    },

    // GET PARAMS //

    SET_UTM_SOURCE(state, source) {
        state.utmSource = source;
    },
    SET_UTM_DATA(state, data) {
        state.utmData = data;
    },

    // SESSION AND AUTH //

    SET_USER(state, user) {
        // alert("SET USER = "+JSON.stringify(user)); // !!
        console.log('SET user: '+JSON.stringify(user)); // !!
        state.user = user;
    },
    SET_JWT(state, token) {
        state.jwt = token;
        if (token) {
            localStorage.setItem('jwt', token);
        } else {
            localStorage.removeItem('jwt');
        }
    },
    SET_REFRESH_TOKEN(state, token) {
        state.refreshToken = token;
        if (token) {
            localStorage.setItem('refreshToken', token);
        } else {
            localStorage.removeItem('refreshToken');
        }
    },
    SET_SESSION_TOKEN(state, token) {
        state.sessionToken = token;
        console.log('SET sessionToken: '+token);
        localStorage.setItem('sessionToken', token);
    },
    CLEAR_AUTH(state) {
        state.user = null;
        state.jwt = null;
        state.refreshToken = null;
        state.walletConnected = false;
        localStorage.removeItem('jwt');
        localStorage.removeItem('refreshToken');
    },

    // GENERAL DATA //
    SET_APP_DATA(state, data) {
        state.appData = data;
    },
    SET_APP_LOAD(state, load) {
        state.appLoad = load;
    },
    SET_APP_ERROR(state, { code, text }) {
        // alert("SET APP ERROR = "+JSON.stringify(code+' '+text)); // !!
        state.appError = true;
        state.appErrorCode = code;
        state.appErrorText = text;
    },

  },
  actions: {

    ...sessActions,
    ...authActions,
    ...userActions,

    // GET PARAMS //

    async setGetParams({ commit }, { utmSource = '', utmData = '', botCode = null, inviteCode = null }) {
        console.log('setGetParams: '+utmSource+' '+utmData+' '+botCode+' '+inviteCode); // !!
        commit('SET_UTM_SOURCE', utmSource);
        commit('SET_UTM_DATA', utmData);
        if(botCode) commit('SET_CODE_BOT', botCode);
        if(inviteCode) commit('SET_CODE_INVITE', inviteCode);
    },

    // NOTIFICATIONS //

    showNotification({ commit }, { message, type }) {
      commit('SET_NOTIFICATION', { message, type });
      setTimeout(() => {
        commit('SET_NOTIFICATION', null);
      }, 5000);
    },

    hideNotification({ commit }) {
        setTimeout(() => {
            commit('SET_NOTIFICATION', null);
        }, 1);
    },

    // SOCKET ALERTS //

    updateCoinList({ commit }, updatedCoin) {
        // This action will be called from the WebSocket module
        // You can implement any global state updates here if needed
        // For now, we'll just pass it through to the component
    },

    showInformation({ commit }, { message, type }) {
        commit('SET_INFORMATION', { message, type });
        setTimeout(() => {
          commit('SET_INFORMATION', null);
        }, 3000);
    },

    hideInformation({ commit }) {
        setTimeout(() => {
            commit('SET_INFORMATION', null);
        }, 1);
    },

    handleCoinUpdate({ commit, dispatch }, updatedCoin) {
        commit('UPDATE_COIN_IN_LIST', updatedCoin);
    },

  },
});

export default store;