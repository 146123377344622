<template>

  <div class="relative min-h-screen">

    <div class="hidden md:block fixed inset-y-0 left-0 w-1 mi:w-2 bg-zinc-100"></div>
    <div class="hidden md:block fixed inset-y-0 left-1 mi:left-2 w-2 mi:w-3 bg-green-600"></div>
    <div class="hidden md:block fixed inset-y-0 left-4 mi:left-5 w-3 mi:w-4 bg-blue-900"></div>
    <div class="hidden md:block fixed inset-y-0 right-4 mi:right-4 w-3 mi:w-4 bg-blue-900"></div>
    <div class="hidden md:block fixed inset-y-0 right-1 mi:right-2 w-2 mi:w-3  bg-green-400"></div>
    <div class="hidden md:block fixed inset-y-0 right-0 w-1 mi:w-2  bg-zinc-100"></div>

    <div class="md:hidden fixed inset-x-0 top-0 h-3 bg-blue-900"></div>
    <div class="md:hidden fixed inset-x-0 bottom-0 h-3 bg-red-900"></div>

    <notify :notification="notification" DEBUG="DEBUG" />
    <inform :information="currentInformation" :notification="notification" v-show="allowInform" DEBUG="DEBUG" />

    <!--
    <TXModal :showTxSuccessModal="showTxSuccessModal" :showTxErrorModal="showTxErrorModal"></TXModal> 
    <botModal :startBotShow="startBotShow" DEBUG="DEBUG" />
    -->

    <div v-if="!appLoad" class="p-10 w-full h-screen"><!-- set true on initSession -->
        
        <div class="mx-auto my-auto text-center pt-20">
            <img src="@/assets/air.svg" class="w-24 md:w-28 mb-10 mx-auto">
            <div class="spinner-32 mx-auto mb-4"></div>
            Inflating the excitement...
        </div>

    </div>
    <div v-else-if="appError">
        <div>{{ appErrorCode }}</div>
        <div>{{ appErrorText }}</div>
    </div>
    <div v-else class="min-w-[320px]">

        <!-- <div class="debug">
            isAuth= {{  isAuth }}<br>
            user = {{ user }}<br>
            jwt = {{ jwt }}<br>
        </div> -->
        <!-- <div class="h-10 w-[320px] bg-yellow-100"></div> -->
        <!-- <div class="debug">
            phantomInstalled = {{ phantomInstalled }}<br>
            solflareInstalled = {{ solflareInstalled }}<br>
            <hr>
            appData = {{ appData }}<br>
            appLoad = {{ appLoad }}<br>
            appError = {{ appError }}<br>
            appErrorCode = {{ appErrorCode }}<br>
            appErrorText = {{ appErrorText }}<br>
            <hr>
            isAuth= {{  isAuth }}<br>
            user = {{ user }}<br>
            jwt = {{ jwt }}<br>
            refreshToken = {{ refreshToken }}<br>
        </div> -->
        <!-- <div v-if="DEBUG" class="debug">
            isStandalone = {{ isStandalone }}<br>
            isIOS = {{ isIOS }}<br>
            isAndroid = {{ isAndroid }}<br>
            showInstallPrompt = {{ showInstallPrompt }}<br>
            showAndroidLoader = {{ showAndroidLoader }}<br>
            deferredPrompt = {{ deferredPrompt }}<br>
        </div> -->

        <connectModal v-if="!isAuth" v-show="openConnect" @hideConnect="hideConnect" @showSign="showSign" DEBUG="DEBUG" />
        <signModal v-show="openSign" @showConnect="showConnect" @hideSign="hideSign" DEBUG="DEBUG" />
        <!-- <profileModal v-show="openProfile" @hideProfile="hideProfile" DEBUG="DEBUG" /> -->

        <div class="max-md:mt-8 mt-5 max-w-[1400px] md:w-[90%] mx-auto mb-5">

            <div class="flex flex-row gap-3 justify-between p-3 mb-3">
            <div class="shrink-0">
                <router-link v-show="!isHome" :to="{ name: 'list' }" class="flex flex-row gap-3 w-fit px-3 py-2 rounded-lg border border-[#405365] pr-4">
                    <img src="@/assets/set/coins.svg" class="w-5 h-5 mx-auto ">
                    Coins
                </router-link>
                <router-link v-show="isHome" :to="{ name: 'launch' }" :class="{ 'c1': isActiveTab('launch') }" class="flex flex-row gap-3 w-fit px-3 py-2 rounded-lg border border-[#405365] pr-4">
                    <img src="@/assets/set/rocket.svg" class="w-5 h-5 mxy-auto ">
                    Launch Coin
                </router-link>
            </div>
            <div class="">
                <router-link :to="{ name: 'list' }" class="mb-4"><img src="@/assets/air.svg" class="w-8 ml-2"></router-link>
            </div>
            <div class="text-right">
                <div v-if="isAuth">
                    <!-- <div>Holding 55 coins worth of 0.4324 SOL</div> -->
                    <!-- <div class="mb-1"><span class="num">55</span> Coins</div> -->
                    <router-link :to="{ name: 'my' }" class="flex flex-row gap-3 w-fit px-3 py-2 rounded-lg border border-[#405365] pr-4"><img src="@/assets/set/bag.svg" class="w-5 h-5 my-auto">My Bags</router-link>
                </div>
                <div v-else>
                    <div class="text-sm text-slate-400">
                        <div v-if="isConnected && !isAuth">
                            No JWT! Please reconnect wallet.
                        </div>
                        <div v-else>
                            <div>4034 Coins</div> 
                            <div>Total mcap 4334.02 SOL
                                <!-- , vol 34.23 SOL -->
                            </div>
                        </div>
                    </div>
                    <!-- <button @click="showConnect()" class="b5 ba whitespace-nowrap">Connect Wallet</button> -->
                </div>
                <!-- <button @click="showConnect()" class="b3 bc my-auto">Connect Wallet</button> -->
            </div>
        </div>

            <div class="max-md:pt-1 pb-5 w-full">

                <!-- <p v-if="error" class="error">{{ error }}</p>
                <div v-if="error" class="error-message">{{ error }}</div> -->

                <header class="flex flex-row gap-2 bg-slate-700 px-3 md:px-4 py-3 md:py-3 mx-3
                rounded-[18px] md:rounded-[32px] backdrop-filter backdrop-blur bg-opacity-40 
                sticky top-6 border border-slate-700 h-16 mb-5">

                    <div class="group flex flex-row w-[30%] max-w-[200px] px-2 pt-1 border border-slate-700 hover:border-[#89D2C3] rounded-3xl">
                        <div class="shrink-0"><img src="@/assets/set/find.svg"
                                class="w-5 h-5 inline-block my-auto opacity-25 group-hover:opacity-55" /></div>
                                <div class="flex-grow">
                                    <input v-model="search" @input="handleSearch" placeholder="$TICKER"
                                    class="w-full border-0 bg-transparent text-lg search focus:outline-none " />
                                </div>
                        <div class="shrink-0" v-show="closeSearch" @click="finishSearch()"><img src="@/assets/set/close.svg" class="w-5 h-5 inline-block my-auto opacity-25 group-hover:opacity-55" /></div>
                    </div>

                    <!-- <WalletConnector /> -->

                    <!-- <div class="flex flex-col gap-2">
                        <div class="text-sm uppercase text-slate-400">Network:</div>
                        <div class="flex flex-row gap-2">
                            ...
                        </div>
                    </div> -->
                    
                    <!-- <div>[network]</div> -->

                    <!-- <div class="my-auto">
                        <div v-if="walletConnected" class="flex flex-col ms:flex-row gap-2">
                            <router-link :to="{ name: 'user' }" class="flex flex-row gap-2">
                                <img src="@/assets/set/wallet.svg" class="h-5 w-5 inline-block"> 
                                <div v-if="user && user.wallet" class="my-auto font-mono text-sm">{{ formatWallet(user.wallet,4) }}</div>
                                <div v-else-if="walletRaw" class="my-auto font-mono text-sm">{{ formatWallet(walletRaw,4) }}</div>
                                <img src="@/assets/set/warn.svg" class="h-5 w-5 inline-block" v-if="!isAuth"> 
                            </router-link>
                            <div v-if="user" class="my-auto ml-2">
                                <router-link :to="{ name: 'my' }">
                                    <span class="border border-slate-800 rounded-md px-2 mr-1 text-sm py-1">{{ user.coins_owned }}</span>
                                    coins
                                </router-link>
                            </div>
                        </div>
                        <div v-else class="text-zinc-500 max-ms:hidden">
                            Connect TON wallet to claim Countries
                        </div>
                        <div class="mt-2" v-if="user">{{  user.coins_owned }} coins</div>
                    </div> -->
                    <div class="ml-auto max-ms:mx-auto shrink-0 flex flex-row gap-2">

                        <div v-if="!isConnected">
                            <button @click="showConnect()" class="max-mv:mt-1 b3 bc">
                                {{ isConnecting ? 'Connecting...' : 'Connect Wallet' }}
                            </button>
                        </div>
                        <div v-else class="flex flex-row gap-3 bg-slate-700 rounded-xl p-2 max-mv:text-sm">
                            <img src="@/assets/set/wallet.svg" class="w-5 h-5 inline-block my-auto">
                            <div class="font-mono my-auto mr-2">{{ formatWallet(walletAddr,4) }}</div>
                            <button @click="disconnectWallet()">
                                <img src="@/assets/set/close.svg" class="w-5 h-5 inline-block my-auto opacity-50">
                            </button>
                        </div>

                        <div class="bg-slate-900 rounded-full shrink-0 p-2">
                            <button v-if="!isAuth" @click="showSign()" class="shrink-0">
                                <img src="@/assets/set/user.svg" class="w-7 h-7 mx-auto">
                            </button>
                            <router-link :to="{ name: 'profile' }" v-else class="shrink-0">
                                <img src="@/assets/set/user.svg" class="w-7 h-7 mx-auto">
                            </router-link>
                        </div>
                        
                        <!-- <select class="text-base p-2 rounded-md h-8 bg-slate-900 text-slate-400 border-slate-800" id="network-select" v-model="selectedNetwork" @change="changeNetwork">
                            <option value="solana">Solana</option>
                            <option value="ton">TON</option>
                            <option value="blast">Blast</option>
                            <option value="base">Base</option>
                            <option value="linea">Linea</option>
                        </select>
                        <button class="b3 bc shrink-0 " v-if="['blast', 'base', 'linea'].includes(currentNetwork)" @click="connectEVM">
                            Connect {{ currentNetwork.charAt(0).toUpperCase() + currentNetwork.slice(1) }} Wallet
                        </button>
                        -->

                    </div>
                </header>

                <div v-if="openTour" class="flex flex-col gap-4 p-5 rounded-2xl mb-5 mx-3 bg-gradient-to-tr from-blue-800 to-orange-800">
                    <div class="flex flex-row gap-5">
                        <div class="flex-grow">
                            <h1>Fuel up the rocket, on the double!</h1>
                        </div>
                        <!-- <h1>Let's get this thing lit</h1> -->
                        <!-- Let's ignite this rocket, on the double! -->
                        <!-- <h1>Gotta fire up this rocket</h1> -->
                        <!-- <h1>Time to fire up</h1> -->
                         <div class="shrink-0"><button @click="hideTour()" class=" mr-1"><img src="@/assets/set/close.svg" class="w-5 h-5"></button></div>
                    </div>
                    <div class="flex flex-col md:flex-row gap-5">
                        <div class="md:w-1/3 bg-slate-900 rounded-xl p-5 bg-opacity-45">
                            <h3 class="mb-2">Find Promising Coins</h3>
                            <div>
                                <div class="mb-2">
                                    All tokens safe,
                                    fair-launch with no presale and no team allocation.
                                </div>
                                <div>
                                 
                                    buy the coin on the bonding curve
                                    sell at any time to lock in your profits or losses
                                </div>
                                <!-- 
                                when enough people buy on the bonding curve it reaches a market cap of $69k
                                12k of liquidity is then deposited in raydium and burned 
                                -->
                            </div>
                        </div>
                        <div class="md:w-1/3 bg-slate-900 rounded-xl p-5 bg-opacity-45">
                            <h3 class="mb-2">Claim SOL from Treasury</h3>
                            <div>
                                Tier 1+ token trading generates Treasury for the token
                                that can be claimed by posting on X and in Telegram.
                                <!-- completing verified tasks. -->
                                <!-- Only erified actions gets SOL. -->
                                <!-- Complete Tasks To Secure Your Allocation from Treasury in SOL -->
                                </div>
                            <div>
                                and trade until the graduation to DEX.
                            </div>
                        </div>
                        <div class="md:w-1/3 bg-slate-900 rounded-xl p-5 bg-opacity-45">
                            <h3 class="mb-2">Launch Your Coin</h3>
                            <div>
                                <!-- And have instant community formed around it. -->
                                Give it 0-48 hours to aggregate community and build up hype around the launch.
                               
                                <!-- Airdrop allocation, -->
                                Under $2, 


                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row gap-5">
                        <router-link :to="{ name: 'about' }" class="b3 ba"  @click.native="hideTour()">Learn more</router-link>
                        <button class="b3 bc ml-auto" @click="hideTour()">Close</button>
                    </div>



                </div>

                <main class="px-4 mt-5 md:mt-5 overflow-x-hidden">
                     
                    <router-view 
                        :windowWidth="windowWidth" 
                        :viewType="viewType"
                        @connectWallet="connectWallet"
                        @changeViewType="changeViewType"
                        :DEBUG="DEBUG">
                    </router-view>

                </main>

                <div class="border-t border-slate-700 pt-5 mt-10 mx-3 max-md:mx-5 pb-7"
                >
   
                    <div class="flex flex-col md:flex-row gap-4">

                        <router-link :to="{ name: 'about' }" class="">
                            <img src="@/assets/set/book.svg" class="w-5 h-5 mb-1 mr-2 inline-block">How it works
                        </router-link>
                        
                        <a href="https://t.me/tonworann" target="_blank" class="md:ml-auto">
                            Follow<img src="@/assets/social/x.svg" class="w-4 h-4 mb-1 ml-2 inline-block opacity-70">
                        </a>
                        <a href="https://t.me/tonworann" target="_blank" class="">
                            Announcements
                            <img src="@/assets/social/t.svg" class="w-5 h-5 mb-1 ml-1 inline-block opacity-70">
                        </a>
                        <!-- <a href="https://t.me/tonworchat" target="_blank" class="">
                            <img src="@/assets/social/t-b.svg" class="w-5 h-5 mb-1 mr-2 inline-block opacity-70">Chat Group
                        </a> -->

                    </div>

                    <!--  :class="{ 'max-md:hidden': !isHome }" -->
                    <div class="mt-5 text-zinc-400 text-sm flex flex-row gap-4 max-md:mt-5" :class="{ 'max-md:hidden': !isHome }">
                        <router-link :to="{ name: 'terms'}" class="text-zinc-400">Terms</router-link>
                        <router-link :to="{ name: 'feedback'}" class="text-zinc-400" >Feedback</router-link>
                    </div>

                </div>

            </div>
        </div>

    </div>

    </div>

</template>
  
<script>

 import { mapState, mapActions, mapGetters } from 'vuex';
 import api from '@/api';
 // import axios from 'axios';

 // import { Connection, PublicKey, Transaction, SystemProgram, clusterApiUrl } from '@solana/web3.js';
 import { Connection, PublicKey, LAMPORTS_PER_SOL, clusterApiUrl } from '@solana/web3.js';


 import notify from '@/modals/Notify.vue';
 import inform from '@/modals/Inform.vue';

 import MainMenu from '@/components/MainMenu.vue';
 
 import connectModal from '@/modals/Connect.vue';
 import signModal from '@/modals/Sign.vue';
import store from './store';
//  import profileModal from '@/modals/Profile.vue';

//  import Bot from '@/modals/Bot.vue';
//  import TX from '@/modals/TX.vue';

 export default {
    name: 'Airpump',
    components: {
        MainMenu, notify, inform,
        connectModal, signModal,
        // profileModal, 
    },
    data() {
        return {

            DEBUG: false,
        
            // Data: null,
            // User: null,

            isAuth: false,
            walletRaw: '-',

            viewType: 1, // global for al lists, depends on window width
            windowWidth: window.innerWidth,

            statsNet: '-',
            statsVol: '-',

            isLoading: false,
            isError: false,
            // isErrorText: null,
            // isErrorCode: null,

            isHome: false, // watch route
            allowInform: true,

            openTour: true,
            openConnect: false,
            openSign: false,
            openPofile: false,

            phantomInstalled: null,
            solflareInstalled: null,

            search: '',
            closeSearch: false,

        }
    },
    computed: {

        ...mapState(['notification']), // , 'information'

        ...mapState('ws', ['infoQueue']),
        currentInformation() {
            return this.infoQueue[0] || null;
        },

        ...mapState(['appData', 'appLoad', 'appError', 'appErrorCode', 'appErrorText']),
        ...mapState(['user', 'jwt', 'refreshToken']),

        ...mapState('wallets', ['isConnecting', 'isConnected', 'walletNetwork', 'walletType', 'walletData', 'walletAddr']),
    
        // ...mapState(['startBotCode', 'startBotShow']),
        
        isAuth() { return !!this.jwt; },

    },
    watch: {
        // walletAddr(newVal, oldVal) {
        //     console.log('Wallet address changed:', newVal);
        //     this.walletRaw = newVal;
        // },
        $route: {
            immediate: true,
            handler(to, from) {
                this.checkRoute(to);
            }
        },
        // openProfile(newValue) {
        //     if (newValue) this.scrollStop(); else this.scrollStart();
        // },
        windowWidth: {
            handler(newWidth) {
                if (newWidth < 520 && this.viewType === 1) {
                this.viewType = 2;
                }
            },
            immediate: true
        },
    },
    async created() {

        // GET parameters
        const getParams = this.getParams();
        await this.setGetParams(getParams);

        const startBotCode = localStorage.getItem('startBotCode');
        if (startBotCode) {
            this.$store.commit('SET_START_BOT_CODE', startBotCode);
        } else {
            console.log('No startBotCode found in local storage.');
        }

        // console.log('Step 1 - Init Session');
        await this.initSession();

        // console.log('Step 2 - Init Phantom');
        await this.initPhantom();
        // console.log('Step 3 - Init Solflare');
        await this.initSolflare();

        this.checkConnection(); 

        // this.statsNet = this.appData.stats.net;
        // this.statsVol = this.appData.stats.vol;

        // // Check/initialize session
        // try {
        //     await this.initSession();
        //     // Session is now initialized or loaded
        // } catch (error) {
        //     console.error('Failed to initialize/load session:', error)
        //     // Error handling is now done in the store action
        // }
        
    },

    mounted() {

        this.initWebSocket();

        // this.$store.dispatch('phantom/init');
        // this.$store.dispatch('solflare/init');

        if(process.env.VUE_APP_DEBUG==1) this.DEBUG=true;

        window.addEventListener('resize', this.updateWidth);

        // Solana Wallets //

        // Double Check
        this.phantomInstalled = this.checkPhantomInstalled();
        this.solflareInstalled = this.checkSolflareInstalled();

        // this.initWalletListeners();
        // this.checkWalletConnection(); // ?? !!

        // window.addEventListener('message', this.handleMessage); // twitter login

    },
    methods: {

        ...mapActions('ws', ['initWebSocket']),

        ...mapActions(['initSession', 'setGetParams']),

        // Wallets
        ...mapActions('wallets', ['checkConnection', 'connectPhantom', 'connectSolflare', 'disconnectWallet', 'signMessage', 'removeAllListeners']),
        ...mapActions('phantom', {initPhantom: 'init' }),
        ...mapActions('solflare', {initSolflare: 'init' }),
        ...mapActions('adapter', {initSolflare: 'init' }),

        // Double Checkers
        checkSolflareInstalled() {
            return 'solflare' in window;
        },
        checkPhantomInstalled() {
            // const isPhantomInstalled = window.phantom?.solana?.isPhantom
            // if not - https://phantom.app/
            // return 'solana' in window && window.solana.isPhantom;
            return 'phantom' in window || 'solana' in window;
        },

        connectWallet() {
            this.showConnect();
        },

    // Search //

    handleSearch() {
      if (this.search.length > 2 || this.search.length === 0) {
        if (this.$route.name !== 'list') {
          this.$router.push({ name: 'list', query: { search: this.search } });
        } else {
          this.$router.push({ query: { ...this.$route.query, search: this.search } });
        }
      }
      if (this.search.length > 2) {
        this.closeSearch = true;
      }
    },

    finishSearch() {
        this.closeSearch = false;
        this.search = '';
        this.closeSearch = false;
        this.handleSearch();
    },

    // old

        // ...mapActions(['connectTONWallet', 'connectSolanaWallet', 'connectEVMWallet']),
        // connectTON() {
        //     this.connectTONWallet();
        // },
        // connectSolana() {
        //     this.connectSolanaWallet();
        // },
        // connectEVM() {
        //     this.connectEVMWallet();
        // },

        // ...mapActions(['setCurrentNetwork']),
        // changeNetwork() {
        //     this.setCurrentNetwork(this.selectedNetwork);
        // },


        // Utility function to get UTM params
        getParams() {

            const urlParams = new URLSearchParams(window.location.search);
            
            const utmSource = urlParams.get('utm_source') || urlParams.get('from') || '';
            
            const utmData = {};
            for (const [key, value] of urlParams.entries()) {
                if (key.startsWith('utm_')) {
                utmData[key] = value;
                }
            }
            const utmDataJson = JSON.stringify(utmData);

            const botCode = urlParams.get('bot') || null;
            const inviteCode = urlParams.get('i') || null;

            console.log('GET' +JSON.stringify({ utmSource, utmDataJson, botCode, inviteCode }));

            return { utmSource, utmData, botCode, inviteCode };

        },

        // getParams() {
        //     const utmSource = this.$route.query.utm_source || this.$route.query.from || '';
        //     alert(JSON.stringify(this.$route.query);
        //     const urlParams = new URLSearchParams(window.location.search);
        //     alert(JSON.stringify(urlParams));
        //     const utmSource = urlParams.get('utm_source') || urlParams.get('utm_from') || '';
        //     // get through router?
        //     // const inviteCode = this.$route.query.invite;
        //     // const botCode = this.$route.query.code;
        //     const utmData = this.getUtmData(); // JSON.stringify all get params with utm_
        //     const botCode = urlParams.get('bot') || null;
        //     // if (botCode) {
        //     //     this.setCodeBot(botCode);
        //     //     console.log('Bot code set:', botCode);
        //     // }
        //     const inviteCode = urlParams.get('i') || null;
        //     // if (inviteCode) {
        //     //     this.setCodeInvite(inviteCode);
        //     //     console.log('Invite code set:', inviteCode);
        //     // }
        //     return { utmSource, utmData, botCode, inviteCode }
        //     // // Remove the parameters from the URL without reloading the page
        //     // ? this.$router.replace({ query: {} });
        //     // if (codeBot || codeInvite) {
        //     //     const newUrl = window.location.pathname + window.location.hash;
        //     //     window.history.replaceState({}, document.title, newUrl);
        //     // }
        // },
        
        // getUtmData() {
        //     const urlParams = new URLSearchParams(window.location.search);
        //     const utmData = {};
        //     for (const [key, value] of urlParams.entries()) {
        //         if (key.startsWith('utm')) {
        //             utmData[key] = value;
        //         }
        //     }
        //     return JSON.stringify(utmData);
        // },

        // Utilities //
    
        scrollStop() { document.body.classList.add('no-scroll'); },
        scrollStart() { document.body.classList.remove('no-scroll'); },

        isActive(name) { return this.$route.name === name; },

        checkRoute(route) {
            console.log('Current route:', route.name);
            this.isHome = route.name === 'list';
            if(!this.isHome) {
                this.hideTour();
            }
            if(route.name === 'launch') {
                this.allowInform = false;
            } else {
                this.allowInform = true;
            }
        },

        // Modals //
        showConnect() {
            this.openConnect = !this.openConnect;
            this.openSign = false;
        },
        hideConnect() {
            this.openConnect = false;
        },
        showSign() {
            this.openSign = !this.openSign;
            this.openConnect = false;
        },
        hideSign() {
            this.openSign = false;
        },
        hideProfile() {
            this.openProfile = false;
        },
        hideTour() {
            this.openTour = false;
        },

        // closeBotShow() {
        //     this.$store.commit('SET_START_BOT_SHOW', false);
        // },

        // doMenu () { this.openMenu = !this.openMenu; },
        // doSignUp () { this.openSignUp = !this.openSignUp; },
        // doSignIn () { this.openSignIn = !this.openSignIn; },
        // doProfile () { this.openProfile = !this.openProfile; },

        // Debug //

        doDEBUG() { this.DEBUG = !this.DEBUG; },

        // Twitter Login

        // async twitterLogin() {
        //     try {
        //         const response = await this.$api.post('auth/twitter_login', {
        //         session: this.$store.state.sessionToken
        //         });
                
        //         if (response.data.status === 'success') {
        //         // Open the Twitter auth URL in a new window
        //         window.open(response.data.auth_url, 'Twitter Login', 'width=600,height=600');
        //         }
        //     } catch (error) {
        //         console.error('Error initiating Twitter login:', error);
        //     }
        // },

        // handleMessage(event) {
        //     if (event.data === 'twitter_login_success') {
        //         alert('twitter_login_success');
        //         // Refresh the page or update the app state as needed
        //         this.$router.go(0);
        //     }
        // },

        // Telegram Login
                    
        // handleTelegramAuth(user) {
        //     // alert('user='+JSON.stringify(user));
        //     // {
        //     // "id": 7192008371,
        //     // "first_name": "Don",
        //     // "last_name": "Tonny",
        //     // "username": "don_tonny",
        //     // "photo_url": "https://t.me/i/userpic/320/facsIiPmpQfSHXNYWl6Bgrn3VAagPI7CFx8k8j3-Muq-pp7dR17HSrNBy_3tC3bm.jpg",
        //     // "auth_date": 1720302552,
        //     // "hash": ""
        //     // }
       
        //     axios.post(`${process.env.VUE_APP_API_URL}auth/telegram`, {
        //         request: { user, from: this.fromCode }
        //     })
        //         .then(response => {
        //             // alert('done' + JSON.stringify(response.data) + '!');
        //             if (response.data.status === 'success') {
        //                 localStorage.setItem('jwt', response.data.jwt);
        //                 // this.noUser = false;
        //                 this.initData = response.data.jwt;
        //                 this.isLoading = true;
        //                 this.updateData().then(() => {
        //                     this.noUser = false;
        //                 });
        //                 // this.isLoggedIn = true;
        //                 // this.userEmail = user.email || 'Telegram User';
        //                 this.error = '';
        //             } else {
        //                 // alert('error telegram auth');
        //                 this.error = response.data.message || 'Authentication failed';
        //             }
        //         })
        //         .catch(error => {
        //         console.error('Telegram auth error:', error);
        //         //   alert('error - '+error);
        //         this.error = 'Authentication failed. Please try again.';
        //         });
        //     },
        //     telegramLoadedCallbackFunc () {
        //         // alert('wigdet ok');
        //         console.log('script is loaded');
        //         this.isTelegramLoaded = true;
        //     },

        // STANDALONE

        checkStandaloneMode() {
        this.isStandalone = window.matchMedia('(display-mode: standalone)').matches ||
                            window.navigator.standalone ||
                            document.referrer.includes('android-app://');
        },

        detectDevice() {
            const userAgent = window.navigator.userAgent.toLowerCase();
            this.isIOS = /iphone|ipad|ipod/.test(userAgent);
            this.isAndroid = /android/.test(userAgent);
        },
        listenForInstallPrompt() {
            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault();
                this.deferredPrompt = e;
                this.showInstallPrompt = true;
            });
        },
        showAndroidInstallLoader() {
            this.showAndroidLoader = true;
            setTimeout(() => {
                this.doneAndroidLoader = true;
                // this.triggerAndroidInstall();
            }, 5000);
        },
        doAndroidInstall() {
            if (this.deferredPrompt) {
                this.deferredPrompt.prompt();
                this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                this.deferredPrompt = null;
                });
            }
            this.showAndroidLoader = false;
        },

        // View //

        changeViewType(viewType) {
            if(this.windowWidth < 420 && viewType === 1) viewType = 2; // ms
            this.viewType = viewType;
        },

        updateWidth() {
            this.windowWidth = window.innerWidth;
            if(this.windowWidth < 420 && this.viewType === 1) this.viewType = 2; // ms
        },

    
    },
        
    beforeUnmount() {
        // Remove event listeners for wallets
        this.removeAllListeners();
        // if (this.provider) {
        //     this.provider.off('disconnect', this.handleDisconnect);
        //     this.provider.off('accountChanged', this.handleAccountChanged);
        // }
        // if (this.isPhantomInstalled()) {
        //     window.solana.removeAllListeners('connect');
        //     window.solana.removeAllListeners('disconnect');
        //     window.solana.removeAllListeners('accountChanged');
        //     window.solana.removeAllListeners('networkChanged');
        // }
        // if (this.isSolflareInstalled()) {
        //     window.solflare.off('connect');
        //     window.solflare.off('disconnect');
        //     window.solflare.off('accountChanged');
        // }
    },
    beforeDestroy() {
        this.scrollStop();
        // if (this.TONConnection) {
        //     this.TONConnection.onStatusChange(null);
        // }
        // Twitter Auth
        // window.removeEventListener('message', this.handleMessage); // twitter auth
        // window.removeEventListener('message', this.handleTelegramAuth);
        // window.removeEventListener('resize', this.updateWidth); // viewType=1
    },
    deactivated() {
        this.scrollStop();
    },

}
</script>