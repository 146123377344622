// store/solanaWalletAdapter.js

import { transact, Web3MobileWallet } from "@solana-mobile/mobile-wallet-adapter-protocol-web3js";
import { Connection, clusterApiUrl } from "@solana/web3.js";

const APP_IDENTITY = {
  name: 'Airpump',
  uri: 'https://airpump.app',
  icon: "favicon.png",
};

const state = {
  isConnected: false,
  isSigning: false,
  publicKey: null,
  isInstalled: false,
};

const mutations = {
  SET_CONNECTED(state, isConnected) {
    state.isConnected = isConnected;
  },
  SET_SIGNING(state, isSigning) {
    state.isSigning = isSigning;
  },
  SET_WALLET_ADDR(state, publicKey) {
    state.publicKey = publicKey;
    this.commit('wallets/SET_WALLET_ADDR', publicKey, { root: true });
  },
  SET_INSTALLED(state, isInstalled) {
    state.isInstalled = isInstalled;
  },
};

const actions = {

  async init({ commit, dispatch }) {
    const isInstalled = await dispatch('checkIfInstalled');
    commit('SET_INSTALLED', isInstalled);
  },

  async connect({ commit, dispatch }) {
    try {

        alert('adapter connect');

        // const authorizationResult = await transact(async (wallet) => {
        //     const authResult = await wallet.authorize({
        //         cluster: 'solana:mainnet-beta', // 'solana:devnet',
        //         identity: APP_IDENTITY,
        //     });
        //     return authResult;
        // });

        const signedMessage = await transact(async (wallet) => {

            // Authorize the wallet session.
            const authResult = await wallet.authorize({
                cluster: 'solana:mainnet-beta', // 'solana:devnet',
                identity: APP_IDENTITY,
            });

            alert('authResult='+JSON.stringify(authResult));
            
            if (authResult) {

                const publicKey = authResult.accounts[0].address;
                commit('SET_WALLET_ADDR', publicKey);
                commit('SET_CONNECTED', true);
                commit('SET_SIGNING', true);

                // Get the message to be signed
                const message = await dispatch('getSignMessage', {
                    publicKey,
                    walletType: 'adapter'
                }, { root: true });

                alert('message='+message);
    
                const messageBuffer = new Uint8Array(
                    message.split('').map(c => c.charCodeAt(0)),
                );

                // Request to sign the payload with the authorized account.
                const signedMessages = wallet.signMessages({
                    addresses: [authResult.accounts[0].address],
                    payloads: [messageBuffer]
                });

                return signedMessages;
                
            } else {
                return null;
            }

        });

        alert('signedMessage='+JSON.stringify(signedMessage));

        // Authenticate with backend
        await dispatch('authenticateWallet', {
            publicKey,
            signedMessage,
            walletType: 'adapter'
        }, { root: true });

        commit('SET_SIGNING', false);

        dispatch('showNotification', { 
            message: 'Mobile Wallet connected successfully', 
            type: 'success' 
        }, { root: true });

    } catch (err) {
      console.error('Solana Mobile Wallet connection error:', err);
      dispatch('showNotification', { 
        message: `Failed to connect Solana Mobile Wallet: ${err.message}`, 
        type: 'error' 
      }, { root: true });
      throw err;
    }
  },

  async disconnect({ commit, dispatch }) {
    try {
      await transact(async (wallet) => {
        await wallet.deauthorize();
      });
      commit('SET_CONNECTED', false);
      commit('SET_WALLET_ADDR', null);
      dispatch('showNotification', { 
        message: 'Solana Mobile Wallet disconnected', 
        type: 'success' 
      }, { root: true });
    } catch (err) {
      console.error('Solana Mobile Wallet disconnection error:', err);
      dispatch('showNotification', { 
        message: `Failed to disconnect Solana Mobile Wallet: ${err.message}`, 
        type: 'error' 
      }, { root: true });
    }
  },

//   async signMessage({ dispatch }, message) {
//     try {
//       const signedMessage = await transact(async (wallet) => {
//         const encodedMessage = new TextEncoder().encode(message);
//         const signature = await wallet.signMessages({
//           addresses: [state.publicKey],
//           payloads: [encodedMessage]
//         });
//         return { signature: signature[0] };
//       });
//       return signedMessage;
//     } catch (error) {
//       console.error("Error signing message:", error);
//       throw error;
//     }
//   },

  async signAndSendTransaction({ dispatch }, transaction) {
    try {
      const connection = new Connection(clusterApiUrl("mainnet-beta"), "confirmed");
      const txSignature = await transact(async (wallet) => {
        const transactionSignatures = await wallet.signAndSendTransactions({
          transactions: [transaction],
        });
        return transactionSignatures[0];
      });

      const confirmationResult = await connection.confirmTransaction(
        txSignature,
        "confirmed"
      );

      if (confirmationResult.value.err) {
        throw new Error(JSON.stringify(confirmationResult.value.err));
      }

      return txSignature;
    } catch (error) {
      console.error("Error signing and sending transaction:", error);
      throw error;
    }
  },


//   async signAndSendTransaction({ dispatch }, transaction) {
//     try {
//       const connection = new Connection(clusterApiUrl("devnet"), "confirmed");
//       const txSignature = await transact(async (wallet) => {
//         const transactionSignatures = await wallet.signAndSendTransactions({
//           transactions: [transaction],
//         });
//         return transactionSignatures[0];
//       });

//       const confirmationResult = await connection.confirmTransaction(
//         txSignature,
//         "confirmed"
//       );

//       if (confirmationResult.value.err) {
//         throw new Error(JSON.stringify(confirmationResult.value.err));
//       }

//       return txSignature;
//     } catch (error) {
//       console.error("Error signing and sending transaction:", error);
//       throw error;
//     }
//   },

  async checkIfInstalled() {
    // This might need to be adjusted based on how you determine if the Mobile Wallet Adapter is available
    return true; // For now, assume it's always installed on mobile
  },
};

export default { namespaced: true, state, mutations, actions };