import { PublicKey } from '@solana/web3.js';
import api from '@/api'; // Make sure this is correctly imported

import bs58 from 'bs58';

const state = {
    isConnected: false,
    isSigning: false,
    publicKey: null,
    isInstalled: false,
};

const mutations = {

    SET_CONNECTED(state, isConnected) {
        state.isConnected = isConnected;
    },
    SET_SIGNING(state, isSigning) {
        state.isSigning = isSigning;
    },
    SET_WALLET_ADDR(state, publicKey) {
        state.publicKey = publicKey;
        this.commit('wallets/SET_WALLET_ADDR', publicKey, { root: true });
    },
    SET_INSTALLED(state, isInstalled) {
        state.isInstalled = isInstalled;
    },

};

const actions = {

    async init({ commit, dispatch }) {
        // console.log('Store - Init solflare dispatched');
        const isInstalled = await dispatch('checkIfInstalled');
        commit('SET_INSTALLED', isInstalled);
        if (isInstalled) {
            const provider = await dispatch('getProvider');
            provider.on('connect', (publicKey) => {
                console.log(`Solflare Event - connect + ${publicKey.toString()}`);
                commit('SET_CONNECTED', true);
                commit('SET_WALLET_ADDR', publicKey.toString());
            });
            provider.on('disconnect', () => {
                console.log(`Solflare Event - disconnect`);
                commit('SET_CONNECTED', false);
                commit('SET_WALLET_ADDR', null);
            });
            provider.on('accountChanged', (publicKey) => {
                if (publicKey) {
                    console.log(`Solflare Event - account changed to ${publicKey.toBase58()}`);
                    commit('SET_WALLET_ADDR', publicKey.toString());
                } else {
                    commit('SET_CONNECTED', false);
                    commit('SET_WALLET_ADDR', null);
                    console.error(`Solflare Event - account changed but no wallet address`);
                }
            });
        }
    },

    async connect({ commit, state, dispatch }) {

        const provider = await dispatch('getProvider');
        if (!provider) {
            throw new Error('Solflare wallet is not installed');
        }
        try {

            await provider.connect();
            if (provider.isConnected) {

                const publicKey = provider.publicKey.toString();
                commit('SET_CONNECTED', true);
                commit('SET_WALLET_ADDR', publicKey);
                commit('SET_SIGNING', true);

                // Get the message to sign from the backend
                const message = await dispatch('getSignMessage', {
                    publicKey,
                    walletType: 'solflare'
                }, { root: true });
                const signedMessage = await dispatch('signMessage', message);

                // Authenticate with backend
                await dispatch('authenticateWallet', {
                    publicKey,
                    signedMessage,
                    walletType: 'solflare'
                }, { root: true });

                dispatch('showNotification', { 
                    message: 'Solflare wallet connected successfully', 
                    type: 'success' 
                }, { root: true });

            }
            
        } catch (err) {
            console.error('Solflare connection error:', err);
            dispatch('showNotification', { 
                message: `Failed to connect Solflare wallet: ${err.message}`, 
                type: 'error' 
            }, { root: true });
            throw err;
        }
    },

    async disconnect({ commit, dispatch }) {
        const provider = await dispatch('getProvider');
        if (provider && provider.isConnected) {
            await provider.disconnect();
            commit('SET_CONNECTED', false);
            commit('SET_WALLET_ADDR', null);
            dispatch('showNotification', { 
                message: 'Solflare wallet disconnected', 
                type: 'success' 
            }, { root: true });
        }
    },

    async signMessage({ dispatch }, message) {
        const provider = await dispatch('getProvider');
        if (!provider || !provider.isConnected) {
            throw new Error('Solflare wallet is not connected');
        }
        const encodedMessage = new TextEncoder().encode(message);
        alert('encodedMessage='+encodedMessage);
        const { signature } = await provider.signMessage(encodedMessage);
        const signedMessage = {
            signature: signature
        };
        alert('signedMessage.signature='+signedMessage.signature);
        return signedMessage;
    },

    // async signTransaction() {
    //     if (!this.isConnected) {
    //       this.setError('Please connect your wallet first');
    //       return;
    //     }
  
    //     try {
    //       // Create a new connection to the selected network
    //       const connection = new Connection(clusterApiUrl(this.network), 'confirmed');
  
    //       // Create a simple transaction (adjust as needed)
    //       let transaction = new Transaction().add(
    //         SystemProgram.transfer({
    //           fromPubkey: this.provider.publicKey,
    //           toPubkey: new PublicKey('Insert a valid public key here'), // Replace with a valid public key
    //           lamports: 1000000, // 0.001 SOL
    //         })
    //       );
  
    //       // Get recent blockhash
    //       transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
    //       transaction.feePayer = this.provider.publicKey;
  
    //       // Sign the transaction
    //       const signed = await this.provider.signTransaction(transaction);
  
    //       console.log('Transaction signed:', signed);
  
    //       // You can send the transaction here if needed
    //       // const signature = await connection.sendRawTransaction(signed.serialize());
    //       // console.log('Transaction sent:', signature);
  
    //       alert('Transaction signed successfully! Check the console for details.');
    //     } catch (error) {
    //       this.setError(`Error signing transaction: ${error.message}`);
    //     }
    //   },

    ifSolflareInstalled() {
        return 'solflare' in window;
    },

    checkIfInstalled() {
        return new Promise((resolve) => {
            if (typeof window !== 'undefined') {
                resolve('solflare' in window);
            } else {
                resolve(false);
            }
        });
    },

    getProvider() {
        return new Promise((resolve) => {
            if (typeof window !== 'undefined') {
                resolve(window.solflare);
            } else {
                resolve(null);
            }
        });
    },

    // getSolflareProvider() {
    //     if ('solflare' in window) {
    //         return window.solflare;
    //     }
    //     return null;
    // },

    removeListeners({ dispatch }) {
        dispatch('getProvider').then(provider => {
            if (provider) {
                provider.off('connect');
                provider.off('disconnect');
                provider.off('accountChanged');
            }
        });
    },

};

export default { namespaced: true, state, mutations, actions };